import {
  MainCore,
  game,
  timeManager,
  CameraTypes,
  CustomEvents,
  THREE,
  settings,
  SettingsTypes,
  AppWSM2021DifficultyTypes,
  AudioManager,
  CameraStates,
  corePhasesManager,
  TimesTypes,
  gameStats,
  fpsManager,
  cameraManager,
  appWSM2021Config,
  modes,
  playersManager,
  requestManager,
  CorePhases
} from '@powerplay/core-minigames'
import {
  audioConfig,
  batchingConfig,
  modelsConfig,
  texturesConfig,
  debugConfig,
  cameraConfig,
  gameConfig,
  translatesReplacements
} from './config'
import {
  type SpecialDataFromInit,
  TexturesNames,
  DisciplinePhases
} from './types'
import { hill } from './entities/hill/Hill'
import { player } from './entities/player'
import { inputsManager } from './InputsManager'
import { disciplinePhasesManager } from './phases/DisciplinePhasesManager'
import { appWSM2021AllDifficultiesConfig } from './config/appWSM2021AllDifficultiesConfig'
import { materialsConfig } from './config/materialsConfig'
import { trainingTasks } from './modes/training/TrainingTasks'
import { stateManager } from './StateManager'
import { pathAssets } from '@/globals/globalvariables'
import { triggersManager } from './entities/trigger/TriggersManager'
import { tutorialFlow } from './modes/tutorial/TutorialFlow'
import { playerAnimationManager } from './entities/player/PlayerAnimationManager'
import { speedManager } from './SpeedManager/SpeedManager'
import * as Sentry from '@sentry/vue'
import {
  debugState,
  speedmeterState,
  splitTimeState,
  trainingResultsState,
  tutorialState
} from '@/stores'
import { loadingState } from '@powerplay/core-minigames-ui'

/**
 * Hlavna trieda pre disciplinu
 */
export class Main {

  /** Hlavna trieda z CORE */
  private mainCore!: MainCore

  /**
   * Konstruktor
   */
  public constructor() {

    this.addListeners()
    // pripravenie konfigov pre WSM 2021 - musime kontrolvat takto kvoli typescriptu
    if (modes.isAppWSM2021()) {

      appWSM2021Config.data = appWSM2021AllDifficultiesConfig[
        modes.mode as unknown as AppWSM2021DifficultyTypes // small TS hack :)
      ]

    }

    if (modes.isTutorial()) {

      loadingState().tutorial = {
        isEnabled: true,
        text: 'disciplineName7'
      }

    }

    /*
     * Nastavenie players konfigov, aby sa dobre zoradovali a mali dobre empty vysledky
     * nemusime zatial nic nastavovat, lebo berieme default hodnoty z konfigu
     * this.setPlayersConfig()
     */

    // spustime CORE veci a pokial vsetko je v pohode, pusti sa INIT metoda
    this.mainCore = new MainCore(
      {
        meshesCastShadows: [],
        materials: materialsConfig,
        callbacks: {
          inputs: {
            callbackLeft: inputsManager.onKeyLeft,
            callbackRight: inputsManager.onKeyRight,
            callbackUp: inputsManager.onKeyUp,
            callbackDown: inputsManager.onKeyDown,
            callbackAction: inputsManager.onKeyAction,
            callbackAction2: inputsManager.onKeyAction2,
            callbackExit: inputsManager.onKeyExit,
            callbackPrepareVideo: inputsManager.onKeyPrepareVideo
          },
          setSpecialDataFromInitRequest: this.setSpecialDataFromInitRequest,
          createAssets: this.createAssets,
          beforeGameStart: this.beforeGameStart,
          updateBeforePhysics: this.updateBeforePhysics,
          updateAfterPhysics: this.updateAfterPhysics,
          updateAnimations: this.updateAnimations
        },
        batching: batchingConfig,
        debugConfig,
        numberOfAttempts: gameConfig.numberOfAttempts
      },
      translatesReplacements,
      {
        textures: texturesConfig,
        models: modelsConfig,
        audio: audioConfig
      }
    )

    this.initialSetup()

  }

  /**
   * Metoda na overenie a zobrazenie FPS
   */
  private checkFpsRequest(): void {

    if (stateManager.getFpsStarted()) {

      const settingsQuality = settings.getSetting(SettingsTypes.quality)
      const fpsData = {
        averageFps: fpsManager.getAverageFpsByQuality(settingsQuality),
        actualFps: fpsManager.getActualFpsByQuality(settingsQuality),
        actualAverageFps: fpsManager.getActualAverageFps()
      }
      stateManager.setFpsData(fpsData)

    }

  }

  /**
   * Pridanie listenerov
   */
  private addListeners() {

    window.addEventListener(CustomEvents.readyForDisciplineInit, this.init)
    window.addEventListener(CustomEvents.loadingProgress, this.loadingProgress)
    // nastavime pocuvanie na zaciatok disciplinovej fazy z CORE
    window.addEventListener(
      CustomEvents.startDisciplinePhase,
      disciplinePhasesManager.setStartPhase
    )

  }

  /**
   * Prvotne nastavanie
   */
  private initialSetup() {

    const localEnv = Number(import.meta.env.VITE_APP_LOCAL) === 1
    this.mainCore.setIsLocalEnv(localEnv)
    game.setIsLocalEnv(localEnv)

    // lokalne si davame ID discipliny, aby sme nemuseli menit v GET parametroch stale
    if (localEnv) requestManager.disciplineID = 7

    AudioManager.PATH_ASSETS = pathAssets
    disciplinePhasesManager.create()

    /*
     * listener na zistenie appky, ze sme v background mode a mame dat pauzu, aby sme setrili
     * prostriedky a aby nehrali zvuky
     */
    window.addEventListener(CustomEvents.toggleBackgroundMode, () => {

      tutorialState().settings = true

    }, false)

  }

  /**
   * Vratenie ignorovanych nazvov textur
   * @returns Pole nazvov ignorovanych textur
   */
  private getIgnoredTexturesNames(): string[] {

    const allRaceTextures = [
      TexturesNames.skierRaceBlackMan,
      TexturesNames.skierRaceBlackWoman,
      TexturesNames.skierRaceMulattoMan,
      TexturesNames.skierRaceMulattoWoman,
      TexturesNames.skierRaceWhiteMan,
      TexturesNames.skierRaceWhiteWoman
    ]

    const usedTextures: string[] = []

    // pridame hraca
    const playerInfo = playersManager.getPlayer()
    usedTextures.push(`${playerInfo.sex}/${TexturesNames.skierRacePrefix}${playerInfo.race}`)

    /*
     * pridame superov, ak su
     * nie su superi
     */

    // vysledok bude rozdiel dvoch poli
    return allRaceTextures.filter(x => !usedTextures.includes(x))

  }

  /**
   * Inicializacia main core
   */
  public init = (): void => {

    // tiene nechceme mat vobec, tak dame toto
    game.shadowsManager.enableCreatingShadowPlaneOnStart = false

    this.mainCore.init(
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      this.getIgnoredTexturesNames(),
      TexturesNames.skierRacePrefix
    )
    this.mainCore.setTweenSettingsForStates(cameraConfig.tweenSettingsForCameraStates)
    cameraManager.changeBaseRenderSettings(0.1, 900)
    trainingTasks.initTraining()
    timeManager.setActive(TimesTypes.total, true)

    if (corePhasesManager.firstInstructions) {

      trainingResultsState().isDisabledPlayAgain = true

    }
    trainingTasks.setShinyTexts()

  }

  /**
   * Zobrazenie progresu loadingu
   */
  private loadingProgress = (): void => {

    gameStats.setNextLoadingPart()
    loadingState().loadingProgress = gameStats.getLoadingProgress()

  }

  /**
   * Nastavenie specialnych dat z init requestu
   * @param data - Specialne data
   */
  private setSpecialDataFromInitRequest = (data: unknown): void => {

    const specialData = data as SpecialDataFromInit

    triggersManager.splitTimeManager.setBestSplit(specialData?.split ?? [])

    if ((specialData?.split ?? []).length <= 0) {

      splitTimeState().noSplitTimes = true

    }

    Sentry.setContext('minigame', {
      id: requestManager.MINIGAME_START_ID,
      idUser: player.uuid
    })

  }

  /**
   * Nastavenie assetov
   */
  private createAssets = (): void => {

    console.log('create assets')
    hill.create()
    player.create()
    hill.placeStartPad()
    this.setUpDebug()

  }

  /**
   * puts singletons into window object
   */
  private setUpDebug(): void {

    if (!Number(import.meta.env.VITE_APP_LOCAL)) return

    const debug = {
      hill,
      inputsManager,
      player,
      disciplinePhasesManager,
      cameraManager: cameraManager,
      setHUDVisibility: () => (debugState().isHudActive = true),
      pauseGame: () => {

        if (game.paused) game.resumeGame()
        else game.pauseGame()

      },
      scene: game.scene,
      game,
      THREE
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).debug = debug

  }

  /**
   * Nastavenie alebo spustenie veci pred startom hry
   */
  private beforeGameStart = (): void => {

    stateManager.setBeforeGameStartPhase()
    triggersManager.splitTimeManager.setSplitCount()

  }

  /**
   * Spustenie veci v update pred vykonanim fyziky
   */
  private updateBeforePhysics = (): void => {

    if (modes.isTutorial()) tutorialFlow.update()
    player.updateBeforePhysics()
    disciplinePhasesManager.update()
    this.checkFpsRequest()

  }

  /**
   * Spustenie veci v update po vykonani fyziky
   */
  private updateAfterPhysics = (delta: number): void => {

    if (
      !corePhasesManager.isActivePhaseByType(CorePhases.intro) &&
      !corePhasesManager.isActivePhaseByType(CorePhases.discipline)
    ) return

    player.updateAfterPhysics(/* hill.hillMesh */)
    const instalerp = [DisciplinePhases.start, DisciplinePhases.preStart].includes(disciplinePhasesManager.actualPhase)
    const { isStatic } = gameConfig.cameraConfig.cameraLookAt

    cameraManager.move(
      player.getPosition().clone(),
      player.getQuaternion().clone(),
      delta,
      [],
      cameraConfig.distanceFromGround,
      cameraManager.isThisCameraState(CameraStates.disciplineOutro),
      {
        lerpCoef: instalerp ? 1 : gameConfig.cameraQuaternionLerp,
        newQuat: isStatic ?
          player.getQuaternion().clone() :
          player.hillLinesManager.cameraQuaternion
      },
      undefined
    )
    stateManager.setUpdateTimeState()

    if (gameConfig.showSpeed && !modes.isTutorial() && !modes.isTrainingMode()) {

      speedmeterState().$patch({
        visible: true,
        speed: Number(speedManager.getActualSpeed().toFixed(2))
      })

    }

  }

  /**
   * Spustenie vykonavania vsetkych animacii
   * @param delta - Delta
   */
  private updateAnimations = (delta: number): void => {

    player.updateAnimations(delta)
    playerAnimationManager.managePrestart2Animation()

  }

  /**
   * Zmena kamery na debug, ak by sme potrebvalo
   */
  public changeCameraToDebug = (): void => {

    cameraManager.setCamera(CameraTypes.debug)

  }

}
